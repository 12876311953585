import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GUI } from "dat.gui";

const LittlestTokyo = () => {
  const canvas = useRef(null);
  let mixer;
  const [guiShown, setguiShown] = useState(false);
  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas.current,
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xbfe3dd);
    const light = new THREE.AmbientLight(0xffffff, 1);
    light.castShadow = true;
    scene.add(light);

    const topLight = new THREE.DirectionalLight(0xdfc718, 2.1); // (color, intensity)
    topLight.position.set(82, 776, 93);
    topLight.castShadow = true;
    scene.add(topLight);

    const camera = new THREE.PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      1,
      100
    );
    camera.position.set(5, 2, 8);
    const clock = new THREE.Clock();
    const controls = new OrbitControls(camera, canvas.current);
    controls.enableDamping = true;
    controls.maxZoom = 20;
    controls.enablePan = false;
    controls.update();
    // controls.maxZoom = 20;
    const stats = new Stats();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("./draco/");
    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    loader.load("./assets/gltf/LittlestTokyo.glb", (gltf) => {
      const model = gltf.scene;
      scene.add(model);
      if (window.innerWidth <= 400) {
        model.scale.set(0.0037, 0.0037, 0.0037);
        model.position.set(0.3, 0.5, 0);
        gui.hide();
        setguiShown(false);
      } else if (window.innerWidth <= 500) {
        model.scale.set(0.005, 0.005, 0.005);
        model.position.set(0.5, 0.5, 0);
        gui.hide();
        setguiShown(false);
      } else {
        model.position.set(1, 0.75, 0);
        model.scale.set(0.01, 0.01, 0.01);
      }
      mixer = new THREE.AnimationMixer(model);
      mixer.clipAction(gltf.animations[0]).play();

      animate();
    });

    const gui = new GUI();
    const handleGui = () => {
      guiShown ? gui.hide() : gui.show();
    };
    handleGui();

    const LightsFolder = gui.addFolder("Lights");
    const TopLightsFolder = gui.addFolder("Directional Lights");

    LightsFolder.add(light, "visible");
    LightsFolder.add(light, "intensity", 0, 10).name("Intensity");
    TopLightsFolder.add(topLight, "visible").name("Light");
    TopLightsFolder.add(topLight, "intensity", 0, 10).name("Intensity");
    TopLightsFolder.add(topLight.position, "x", 0, 1000).name("X");
    TopLightsFolder.add(topLight.position, "y", 0, 1000).name("Y");
    TopLightsFolder.add(topLight.position, "z", 0, 1000).name("Z");
    window.onresize = function () {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    function animate() {
      requestAnimationFrame(animate);

      const delta = clock.getDelta();

      mixer.update(delta);

      controls.update();

      stats.update();

      renderer.render(scene, camera);
    }
  }, []);

  return (
    <div className="bg-[#bfe3dd]">
      <h1 className="relative text-lg font-medium z-40 text-center bg-transparent">
        Littlest Tokyo Clone by{" "}
        {/* <button
          onClick={() => {
            setguiShown(!guiShown);
          }}
          className="cursor-pointer max-[500px]:block hidden fixed top-0 left-0 z-100"
        >
          {guiShown ? "Hide" : "Show"} Controls
        </button> */}
        <p className="text-sm font-light">
          Created by{" "}
          <a
            href="https://cermuel.vercel.app"
            className="text-blue-600 font-semibold cursor-pointer"
          >
            CERMUEL
          </a>{" "}
        </p>
      </h1>

      <canvas ref={canvas}></canvas>
    </div>
  );
};

export default LittlestTokyo;
